<style scoped>
.retry-after-wrapper {
  border-radius: 5px;
}
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
/* .tab-status-underline::after{
  content: '';
  position: absolute;
  width: 25%;
  height: 5px;
  left: 0;
  top: 49px;
  background: red;
} */
.bank-statment-rows {
  border-bottom: 1px solid #0000001a;
}
.hover-bg:hover {
  background-color: #00000016;
}
</style>
<template>
  <div>
    <DivLoader
      v-if="loader"
      :loading_type="
        'image, list-item-two-line, image, list-item-two-line, image, list-item-two-line, image, list-item-two-line, image, list-item-two-line'
      "
    ></DivLoader>
    <!-- 'list-item-two-line, list-item-two-line, image, table-tfoot' -->
    <div v-else>
      <!-- <div class="text-h6 font-weight-bold mb-5 d-flex">
        <p>Basic Details</p>
      </div>
      <v-card elevation="5">
        <v-card-text class="pa-0"> </v-card-text>
      </v-card> -->
      <BasicDetailsSection></BasicDetailsSection>
      <PersonalDetailsSection></PersonalDetailsSection>
      <DocumentsSection></DocumentsSection>
      <BusinessDetailsSection></BusinessDetailsSection>
    </div>

    <!-- <UploadBankStatement @onSuccess="getBankDetails" ref="uploadBankStatementDialog"></UploadBankStatement> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      loader: false,
    };
  },
  mounted() {},
  components: {
    BasicDetailsSection: () =>
      import("@/components/business-loan/sections/BasicDetailsSection"),
    PersonalDetailsSection: () =>
      import("@/components/business-loan/sections/PersonalDetailsSection"),
    DocumentsSection: () =>
      import("@/components/business-loan/sections/DocumentsSection"),
    BusinessDetailsSection: () =>
      import("@/components/business-loan/sections/BusinessDetailsSection"),
  },
  methods: {},
};
</script>
